.message-button {
  background-color: $Mindaro_Yellow-Green !important;
  color: $Cool_Black;
  border: none;
}

.my_network_header {
  @media (max-width: 600px) {
    top: 78px;
  }
  @media (max-width: 1280px) {
    top: 84px;
  }
  .filter-wrap {
    display: flex;
    justify-content: space-between; /* Push elements to the edges */
    align-items: center; /* Vertically center the items */
    width: 100%; /* Ensure full width */

    @media (max-width: 600px) {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      gap: 0.5rem; /* Add spacing for vertical layout */
    }
    .ant-segmented-thumb {
      background-color: $basegray-300 !important; /* Change the active thumb background color */
      border-radius: 8px; /* Add rounded corners to the thumb */
      transition: all 0.3s ease; /* Smooth transition when the thumb moves */
      height: 100%; /* Ensure the thumb covers the full height */
      z-index: 1; /* Make sure the thumb stays below the text */
      color: $Pure_White;
      opacity: 0.5;
    }

    .ant-segmented {
      background-color: $Cool_Black;
      border: 2px solid $basegray-300;
    }
    .ant-segmented-item {
      color: $gray_color;
      &:hover {
        background-color: none !important;
        color: $gray_color !important;
      }
    }
    .ant-segmented-item-selected {
      background-color: $basegray-300 !important;
      color: $Pure_White !important;
    }
    .segmented-custom {
      flex-shrink: 0; /* Prevent Segmented control from stretching */
      margin-right: auto; /* Ensure it is flush to the left edge */

      @media (max-width: 600px) {
        display: none; /* Hide segmented control on mobile */
      }
    }

    .search_div {
      flex-shrink: 0; /* Prevent search bar from stretching */
      display: flex;
      align-items: center;
      margin-left: auto; /* Push search bar to the right edge */

      @media (max-width: 600px) {
        width: 100%; /* Take up the full width on mobile */
        margin-left: 0; /* Center the search bar */
      }

      .search-input-field {
        width: 350px; /* Allow natural width for the search input */
        height: 44px;
        @media (max-width: 600px) {
          width: 100%; /* Full width for the search input on mobile */
        }
      }

      .filter_icon {
        margin-left: 8px;
        @media (max-width: 600px) {
          margin-left: -10px;
        }
      }
      .more_icon {
        display: none;
        @media (max-width: 600px) {
          color: $gray_color;
          display: block;
          padding-left: 12px;
        }
      }
    }
  }

  .exp-container-wrap {
    margin: 1.875rem 0.75rem;

    @media (max-width: 1220px) {
      margin: 0px;
    }
  }
}

.my-network-table {
  margin-top: 0.5rem;
  .custom-row {
    height: 50px; /* Default row height */
  }

  @media (max-width: 768px) {
    .custom-row {
      height: 100px !important; /* Larger height for mobile screens */
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: default;
    height: 18px;
    width: 18px;

    &::after {
      border: none;
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: space-between; /* Aligns arrows and page numbers */
    align-items: center;
    /* Left Arrow */
    .ant-pagination-prev {
      margin-right: auto; /* Keeps it on the left edge */
    }

    /* Page Numbers */
    .ant-pagination-item {
      background-color: $Cool_Black;
      border: 1px solid $light_Black;
      margin: 0 2px; /* Adjust spacing between page numbers */
      a {
        color: $Pure_White;
      }
    }

    .ant-pagination-item-active {
      background-color: $Cool_Black;
      border: 1px solid $light_Black;
      a {
        color: $Mindaro_Yellow-Green !important;
      }
    }

    /* Center Align the Page Numbers */
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    /* Right Arrow */
    .ant-pagination-next {
      margin-left: auto; /* Keeps it on the right edge */
    }

    /* Arrow Styles */
    .ant-pagination-item-link {
      color: $Pure_White;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
      color: $Pure_White;
    }

    /* Fix rendering issue for the last page */
    .ant-pagination-item:last-child {
      margin-right: 0;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    border: 1px solid #444 !important;
    border-radius: 2px !important;
    width: 16px !important;
    height: 16px !important;
  }

  .ant-table-thead > tr > th::before {
    content: none !important;
    display: none !important;
    border-bottom: 1px solid #444; /* Header bottom border */
  }

  .ant-table {
    background: #1f1f1f;
    color: #fff;
    border: 2px solid #444;
  }

  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .small-avatar {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(32%, 45%);
  }

  .ant-table-thead > tr > th {
    background: $basegray-300;
    color: #fff;
    border-bottom: 1px solid #444; /* Header bottom border */
  }

  //here
  .ant-table-tbody > tr > td {
    background: $Cool_Black;
    color: #fff;
    border-bottom: 1px solid #444; /* Header bottom border */
  }
  @media (max-width: 600px) {
    .ant-table-tbody > tr > td {
      padding: 20px 8px !important; /* Increased padding for mobile */
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #191b1e !important; /* Change this to your desired color */
  }
  .ant-table-tbody > tr:hover > td {
    background: none !important;
  }

  .ant-tag {
    background: #444;
    color: #fff;
    border: none;
  }
}

.explore-filter-panel {
  width: 440px;
  max-height: fit-content;
  height: 95vh;
  border-radius: 8px;
  border-radius: 0.25rem;
  border: 1px solid #515151;
  background: #191b1e;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  scrollbar-width: auto;
  position: fixed;
  right: 0;
  top: 15px;
  color: #ffffff;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  @media (max-width: 600px) {
    width: 100%;
    top: auto;
    bottom: 0;
    transform: translateY(100%);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  .filter_panel_flex {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .filter_header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #191b1e;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    border-radius: 0.25rem 0.25rem 0 0;

    h3 {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem;
      color: $Pure_White;
    }
    .anticon {
      cursor: pointer;
      svg {
        path {
          stroke: #a1a1a1;
        }
      }
    }
  }

  .filter_menu {
    .filter_lists {
      overflow-y: auto;
      height: 100vh;
      padding: 0rem 1.5rem 1rem;

      @media (max-width: 600px) {
        max-height: 60vh;
      }
    }
    .drop-down-btn {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding-bottom: 1rem;
    }

    .filter_lable {
      color: $gray_color;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      padding-bottom: 0.25rem;
    }

    .label_box {
      .label_text {
        color: $Pure_White;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }

    .filter_selected {
      margin-top: 1rem;
      display: none;

      .ant-btn-default {
        font-size: 16px !important;

        @media (max-width: 600px) {
          font-size: 14px !important;
          padding: 0 10px;
        }

        &:hover .close_btn {
          color: $Cool_Black;
        }
      }

      .close_btn {
        color: $Sand;
        padding-left: 0.75rem;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .filter_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-block-start: 1px solid $gray_color;
    position: sticky;
    bottom: 0; /* Position at the bottom */
    z-index: 10;
    border-radius: 0 0 0.25rem 0.25rem;
    background: #191b1e;
    .reset-btn {
      color: $Mindaro_Yellow-Green;
      border: none;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      &:hover {
        color: $Mindaro_Yellow-Green;
      }
    }
    .save-btn {
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 20px;
      &:hover {
        background: transparent !important;
        color: $Pure_White;
      }

      @media (max-width: 600px) {
        width: fit-content;
      }
    }
  }
}

.explore-filter-panel.show {
  transform: translateX(0);
}
.explore-filter-panel.hide {
  transform: translateX(100%);
}
@media (max-width: 600px) {
  .explore-filter-panel.show {
    transform: translateY(0);
  }

  .explore-filter-panel.hide {
    transform: translateY(100%);
  }
}
