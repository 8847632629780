.explore_header {
  position: sticky;
  top: 112.8px;
  z-index: 10;
  @media (max-width: 600px) {
    top: 84.8px;
  }
  @media (max-width: 1280px) {
    top: 84.8px;
  }

  .filter-wrap {
    color: $Pure_White !important;
    background: $Cool_Black;
    padding: 1.5rem 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 0 1rem 0;
    }
    .search_div {
      max-width: 416px;
      width: 100%;
      @media (max-width: 600px) {
        max-width: 100%;
      }
      display: flex;
    }

    .search-input-field {
      width: 100%;
      height: 2.75rem;
      padding: 0.5rem 0.75rem;
      position: relative;
      background-color: $light_Black;
      border-radius: 4px;
      overflow: hidden;
      border: none;
      font-family: $work-sans_font;
      font-weight: 400;
      color: $Pure_White;
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 24px;
      white-space: nowrap;
      font-style: normal;

      &::placeholder {
        color: $gray_color;
        text-transform: capitalize;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .drop-down-btn {
    .ant-btn-default {
      color: $Pure_White;
      border: 1px solid $Pure_White;
      padding: 0 10px;
      font-size: 16px !important;

      @media (max-width: 600px) {
        font-size: 14px !important;
      }
    }

    .ant-input {
      background-color: transparent !important;
      color: #fff;
      border-radius: 4px;
      &::placeholder {
        color: #fafafa;
      }
    }

    .ant-input-search-button {
      background-color: transparent !important;
      border: 0.25px solid #fafafa;
    }
  }

  .exp-container-wrap {
    margin: 1.875rem 0.75rem;

    @media (max-width: 1220px) {
      margin: 0px;
    }
  }
}

.ant-dropdown {
  margin: 0 14px;
  .ant-dropdown-menu {
    overflow: auto;
    max-height: 40dvh;
    background: $Cool_Black;
    border: 1px solid $light_Black;

    .ant-dropdown-menu-item {
      color: $Pure_White;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 12px;

      &:hover {
        background: #5e60714d;
      }

      .anticon {
        color: $Periwinkle;
        font-size: 16px;
        margin-right: 0.5rem;
      }
    }
  }
}

.switch-wrap {
  flex: 1 0 0;
  .ant-tabs-ink-bar {
    background-color: white !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    text-align: center;
    justify-content: center;
    display: flex;
    border-bottom: 2px solid $gray_color;
    margin: 0;
    padding-left: 1em;
    padding-right: 1em;
  }
  @media (max-width: 600px) {
    width: 100%;
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-nav .ant-tabs-tab {
      width: 50vw;
      text-align: center;
      justify-content: center;
      display: flex;
      border-bottom: 2px solid $gray_color;
      margin: 0;
    }
  }

  .exp_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: $Celadon_Green;
    letter-spacing: 0px;

    @media (max-width: 600px) {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      display: none;
    }

    span {
      color: $Mindaro_Yellow-Green;
    }
  }

  .filter_btn {
    display: none;
    box-shadow: none !important;

    @media (max-width: 567px) {
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
    }
  }

  .filter_btn.inactive {
    .ant-btn {
      background-color: $Cool_Black !important;
      border-radius: 0 !important;
      border: 1px solid $border-color;

      .anticon-filter {
        color: $Pure_White;
      }
    }
  }

  .filter_btn.active {
    .ant-btn {
      background-color: $Cool_Black !important;
      border-radius: 0 !important;
      border: 1px solid $Mindaro_Yellow-Green;

      .anticon-filter {
        color: $Pure_White;
      }
    }
  }

  .flight-types .checked .switch_label {
    color: $base-color !important;
    transition: all 1s;
  }

  .flight-types .checked:nth-of-type(2) .switch_label {
    &:last-of-type:before {
      transform: translateX(calc(100% + 1.5px));
    }
  }
}

.card_container {
  .list-wrap {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-direction: column-reverse;
  }
}

.Opportunitie_layout.single-column {
  .layout-grid {
    display: grid;
    grid-template-columns: 400px;
    max-width: 420px;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 100vh;
    scroll-snap-type: y mandatory;
    padding-right: 8px;

    .active {
      .main-card {
        border: 2px solid $Celadon_Green;
        border-radius: 4px;
      }
    }
  }
}

.inves_list_wrap {
  .single-column {
    .layout-grid {
      display: grid;
      grid-template-columns: 400px;
      max-width: 420px;
      row-gap: 1.5rem;
      column-gap: 1.5rem;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: auto;
      max-height: 100vh;
      scroll-snap-type: y mandatory;
      padding-right: 8px;

      .active {
        .main-card {
          border: 2px solid $Celadon_Green;
          border-radius: 4px;
        }
      }
    }
  }
}

.single-column {
  .layout-grid {
    display: none;
  }

  .slide-view {
    width: 100%;
    max-width: 1280px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    overflow: auto;
    min-height: 100dvh;
    padding: 0.75rem;
    display: block;
    background-color: $Cool_Black;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
    @media (max-width: 600px) {
      padding: 0 0 50px 0;
    }

    .slide-header {
      margin: 0.5rem 0;
    }

    .ant-breadcrumb {
      display: none;
    }

    .anticon-close-circle {
      color: $Pure_White;
    }
  }
}

.filter_icon {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: $light_Black;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  .filter_count {
    width: 1.12rem;
    height: 1.12rem;
    background: #ffffff;
    color: $Cool_Black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 0.61556rem;
    font-weight: 700;
    top: -5px;
    right: -5px;
    border-radius: 26px;
  }
}
.filter-panel.show {
  transform: translateX(0);
}
.filter-panel.hide {
  transform: translateX(100%);
}

.filter-panel {
  width: 440px;
  max-height: fit-content;
  height: 95vh;
  border-radius: 8px;
  border-radius: 0.25rem;
  border: 1px solid #515151;
  background: #191b1e;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  scrollbar-width: auto;
  position: fixed;
  right: 0;
  top: 15px;
  color: #ffffff;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  @media (max-width: 480px) {
    width: 100%;
  }
  .filter_panel_flex {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .filter_header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #191b1e;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    border-radius: 0.25rem 0.25rem 0 0;

    h3 {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem;
      color: $Pure_White;
    }
    .anticon {
      cursor: pointer;
      svg {
        path {
          stroke: #a1a1a1;
        }
      }
    }
  }

  .filter_menu {
    .filter_lists {
      overflow-y: auto;
      max-height: 66vh;
      padding: 0rem 1.5rem 1rem;
      padding-bottom: 4rem; /* Adjust as needed to ensure buttons are visible */

      @media (max-width: 600px) {
        max-height: 60vh;
      }
    }
    .drop-down-btn {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding-bottom: 1rem;
    }

    .filter_lable {
      color: $gray_color;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      padding-bottom: 0.25rem;
    }

    .label_box {
      .label_text {
        color: $Pure_White;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }

    .filter_selected {
      margin-top: 1rem;
      display: none;

      .ant-btn-default {
        font-size: 16px !important;

        @media (max-width: 600px) {
          font-size: 14px !important;
          padding: 0 10px;
        }

        &:hover .close_btn {
          color: $Cool_Black;
        }
      }

      .close_btn {
        color: $Sand;
        padding-left: 0.75rem;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .filter_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-block-start: 1px solid $gray_color;
    position: sticky;
    bottom: 0; /* Position at the bottom */
    z-index: 10;
    border-radius: 0 0 0.25rem 0.25rem;
    background: #191b1e;
    .reset-btn {
      color: $Mindaro_Yellow-Green;
      border: none;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      &:hover {
        color: $Mindaro_Yellow-Green;
      }
    }
    .save-btn {
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 20px;
      &:hover {
        background: transparent !important;
        color: $Pure_White;
      }

      @media (max-width: 600px) {
        width: fit-content;
      }
    }
  }
}

.explore-filter-panel {
  width: 440px;
  max-height: fit-content;
  height: 95vh;
  border-radius: 8px;
  border-radius: 0.25rem;
  border: 1px solid #515151;
  background: #191b1e;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  scrollbar-width: auto;
  position: fixed;
  right: 0;
  top: 15px;
  color: #ffffff;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  @media (max-width: 480px) {
    width: 100%;
  }
  .filter_panel_flex {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .filter_header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #191b1e;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    border-radius: 0.25rem 0.25rem 0 0;

    h3 {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem;
      color: $Pure_White;
    }
    .anticon {
      cursor: pointer;
      svg {
        path {
          stroke: #a1a1a1;
        }
      }
    }
  }

  .filter_menu {
    .filter_lists {
      overflow-y: auto;
      height: 100vh;
      padding: 0rem 1.5rem 1rem;

      @media (max-width: 600px) {
        max-height: 60vh;
      }
    }
    .drop-down-btn {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding-bottom: 1rem;
    }

    .filter_lable {
      color: $gray_color;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      padding-bottom: 0.25rem;
    }

    .label_box {
      .label_text {
        color: $Pure_White;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }

    .filter_selected {
      margin-top: 1rem;
      display: none;

      .ant-btn-default {
        font-size: 16px !important;

        @media (max-width: 600px) {
          font-size: 14px !important;
          padding: 0 10px;
        }

        &:hover .close_btn {
          color: $Cool_Black;
        }
      }

      .close_btn {
        color: $Sand;
        padding-left: 0.75rem;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .filter_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-block-start: 1px solid $gray_color;
    position: sticky;
    bottom: 0; /* Position at the bottom */
    z-index: 10;
    border-radius: 0 0 0.25rem 0.25rem;
    background: #191b1e;
    .reset-btn {
      color: $Mindaro_Yellow-Green;
      border: none;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      &:hover {
        color: $Mindaro_Yellow-Green;
      }
    }
    .save-btn {
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 20px;
      &:hover {
        background: transparent !important;
        color: $Pure_White;
      }

      @media (max-width: 600px) {
        width: fit-content;
      }
    }
  }
}

.filtersDiv {
  display: none;
}
.list-wrap.hide .slide-view {
  max-width: 0%;
  width: 0%;
  transition: all 300ms;
}

.connect-page {
  .page-title {
    text-align: left;
    margin-bottom: 10px;
  }

  .con-lists {
    max-width: 746px;
    width: 100%;

    .ant-card-head {
      padding: 0 !important;
    }

    .ant-card-head-title {
      text-align: left;
      border-bottom: 1px solid #d9d9d9;
      padding: 0.5rem 1rem;
    }

    .ant-card-body {
      padding: 0 !important;
    }

    // .demo-loadmore-list {
    //     min-height: 350px;
    //   }

    .ant-list {
      .ant-list-item {
        &:hover .ant-list-item-meta-title a {
          text-decoration: underline;
          text-underline-offset: 4px;
        }

        &:hover {
          background: $active-bg;
          box-shadow: none !important;
        }

        .ant-list-item-meta-avatar {
          padding-left: 1rem;
        }

        .ant-list-item-meta-title {
          text-transform: capitalize;
          text-align: left;

          a {
            padding-left: 1rem;
            // &:hover{
            //     text-decoration: underline;
            // }
          }
        }

        .ant-list-item-meta-description {
          text-transform: capitalize;
          padding-left: 1rem;
          text-align: left;
          color: $base-color;
          word-break: break-word;
        }

        &:hover .ant-card-head-title a {
          text-decoration: underline;
        }

        .ant-btn-link.ant-btn-dangerous {
          color: rgba(255, 2, 2, 0.5) !important;
        }
      }
    }
  }

  .flex_wraper_conn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 567px) {
      flex-direction: column;
      gap: 1rem;
    }

    .estb_content_wrapper {
      max-width: 770px;
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 6px;

      .Connection_wraper {
        .page-title {
          padding-left: $size;
        }

        .ant-tabs-nav-wrap {
          padding-left: $size;
        }

        .received {
          border: none;
        }

        .sent {
          border: none;
        }

        .wrapper_connections {
          display: flex;
          justify-content: space-between;
          align-content: center;
          gap: $size;
        }

        .connections_info {
          max-width: 249px;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
          min-height: 452px;
        }
      }

      .list_connections {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        border-bottom: 1px solid #d9d9d9;

        @media (max-width: 567px) {
          flex-wrap: wrap;
          padding: 0.75rem;
          // flex-direction: column;
          justify-content: flex-start;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:hover h3 a {
          text-decoration: underline;
          text-underline-offset: 4px;
        }

        &:hover {
          background: $active-bg;
          box-shadow: none !important;
        }

        .ant-avatar {
          // padding-left: 1rem;
          img {
            object-fit: contain !important;
          }
        }

        .info_connections {
          text-align: left;
          padding-left: 1rem;
          text-transform: capitalize;
          flex: 1;

          h3 {
            margin: 0;

            a {
              color: $base-color;
              font-weight: 500;
            }
          }

          .description {
            color: $base-color;
            font-weight: 400;
            word-break: break-word;
          }
        }

        .btn_actions {
          @media (max-width: 567px) {
            margin-left: auto;
            margin-top: 1rem;
            border-top: 1px solid #d9d9d9;
            width: 100%;
          }

          .ant-btn-link.ant-btn-dangerous {
            color: rgba(255, 2, 2, 0.5) !important;
          }

          .text-status {
            text-transform: uppercase;
            font-size: $smallsize;
            padding-right: 15px;
          }
        }
      }
    }

    .view-page {
      max-width: 320px;
      border: 1px solid #d9d9d9;
      background-color: #fff;
      min-height: 452px;
      border-radius: 6px;
      margin: 0;
      padding: $smallsize;
      display: flex;
      flex-direction: column;

      .view_info {
        text-align: left !important;

        h3 {
          margin-top: 0px !important;
        }
      }

      .label-group {
        padding-bottom: $smallsize;

        p {
          margin: 0;
          color: $base-color;
        }
      }

      .btn-more {
        text-align: center !important;
        border-width: 3px;
        border-color: $Mindaro_Yellow-Green;
        color: $Mindaro_Yellow-Green;
        margin-top: auto;
        width: 100%;
      }
    }
  }
}

.side-view-comapny {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  // background: $Card_bg_color;
  @media (max-width: 600px) {
    padding: 16px;
  }
  .cvr_title {
    // padding-bottom: 1rem;

    h2 {
      font-size: 32px;
      font-weight: 400;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .cvr_flex_wrapping {
    display: flex;
    gap: 1rem;
    margin-top: 30px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .logo-company {
      min-width: 518px;
      flex: 1;

      @media (max-width: 600px) {
        max-width: 518px;
        min-width: fit-content;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 10px;
      }
    }
  }

  .colounm_two {
    display: flex;
    flex-direction: column;
    flex: 1;

    .soft_green:first-child {
      padding: 0rem 0 0.5rem 0;
    }
  }

  .org-status-action {
    text-align: center;
    padding: 1rem 0;
    margin-top: auto;

    .card_status {
      text-align: center;
      font-size: 1.125rem;
      color: #f0eee9;
      text-transform: capitalize;
      line-height: 40px;
    }

    a {
      color: $Pure_White;
    }

    .ant-btn-default {
      width: 100%;
    }
  }
}

.popover_ms {
  display: none;

  @media (max-width: 576px) {
    display: block !important;
  }
}

.comapany-user-list {
  .list_group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      column-gap: 15px;
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      column-gap: 15px;
    }
  }
}

video {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

@media (max-width: 1024px) {
  .single-column {
    margin: 0rem;

    .layout-grid {
      width: 0% !important;
      display: none !important;
    }
  }
}

@media (max-width: 567px) {
  .single-column {
    margin: 0rem;
    margin-bottom: 3rem;
  }
}

.org_notes {
  .ant-row {
    @media (max-width: 567px) {
      padding: 0;
    }
  }

  .border_right {
    border-right: 1px solid #c1c1c1;

    @media (max-width: 567px) {
      border: none;
      border-bottom: 1px solid #c1c1c1;
    }
  }

  .note_edit {
    background-color: #fff;
    padding: 1rem;
    width: 100%;

    .ant-form {
      .ant-form-item {
        margin-bottom: 1.5rem !important;
      }
    }
  }

  .note_left_view {
    border-radius: 0 !important;
    border: none !important;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_label {
        color: #b9b9b9;
        font-weight: 600;
      }
    }

    .note_list {
      padding: 1rem;

      .note_title {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        color: #b9b9b9;
      }
    }
  }

  .note_preview {
    background-color: #fff;
    padding: 1rem;

    .note_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .current_date {
        font-size: 14px;
        font-weight: 700;
        color: #b9b9b9;
      }
    }

    .note_title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .note_description {
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      word-break: break-all;
      word-break: break-word;
    }
  }
}

.modal_h_scroll {
  overflow-x: auto;
  max-width: 83vw;
  padding: 0.5rem;
}

.about_info {
  padding: 8px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  word-break: break-word;

  @media (max-width: 600px) {
    font-size: 14px !important;
  }

  a {
    color: $Celadon_Green;
  }
}

div.img-list-space {
  background-color: $light_Black;
  overflow: auto;
  white-space: nowrap;
  padding: 9.5px;
  border-radius: 0 6px 6px 0px;

  h2,
  p {
    color: $Pure_White;
  }
}

.estate-img-space {
  margin-bottom: 30px;

  h3 {
    color: $Pure_White;
  }
}

.estate-img-wrap {
  max-width: 300px;
  width: 100%;
  position: relative;
  display: inline-block;
  height: 200px;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.estate-img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.estate-img-wrap .close-icon {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 0px;
  color: $Cool_Black;
  display: none;
  background: #fff;
}

.estate-img-wrap:hover .close-icon {
  display: block;
}

//  new code css//

.flex_wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  gap: 2rem;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
  }

  .list-info {
    > label {
      display: block;
      color: $Pure_White;
      line-height: 20px;
    }

    > b {
      font-size: 16px;
      display: block;
      font-family: $work-sans_font;
      color: $Pure_White;
      line-height: 20px;
    }
  }
}

.filter_space {
  display: flex;
  align-items: center;
  justify-content: center;
  .flight-types {
    display: flex;
    background-color: $light_Black;
    position: relative;
    z-index: 1;
    font-size: 16px;
    border-radius: 22px;
    transition: all 1s;

    .switch_label {
      cursor: pointer;
      padding: 8px 20px;
      display: flex;
      width: 179px;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-1, 4px);
      flex-shrink: 0;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $Pure_White !important;
      }
    }

    .checked {
      span {
        color: $Cool_Black !important;
      }
      cursor: pointer;
      background-color: $Mindaro_Yellow-Green !important;
      border-radius: 22px;
    }
  }
}

.admin-view {
  .filter_space {
    .flight-types {
      display: flex;
      background-color: $Cool_Black;
      position: relative;
      z-index: 1;
      font-size: 16px;
      border: 1px solid $Pure_White;
      border-radius: 22px;
      transition: all 1s;

      .switch_label {
        cursor: pointer;
        padding: 10px 20px;
        color: $Pure_White !important;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
      }

      .checked {
        cursor: pointer;
        color: $base-color !important;
        background-color: $Mindaro_Yellow-Green !important;
        border-radius: 22px;
      }
    }
  }

  .conn_status {
    display: none !important;
  }

  .org-status-action {
    display: none !important;
  }
  .sub_header_actions {
    display: none !important;
  }

  .ant-table-wrapper {
    .ant-table {
      background: $Cool_Black !important;
      color: $Pure_White !important;
      scrollbar-color: rgb(158 158 158 / 25%) rgba(5, 5, 5, 0.06);
      border: 1px solid $light_Black;
      overflow: auto;
    }

    .ant-table-thead > tr > th {
      background: $light_Black !important;
      color: $Pure_White !important;
      border-bottom: 1px solid $light_Black;
    }
    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: unset;
    }
    .ant-table-cell-row-hover {
      background: transparent !important;
      box-shadow:
        inset 0 0 10px rgba(25, 27, 30, 0.5),
        inset 0 0 20px rgba(25, 27, 30, 0.5),
        inset 0 0 30px rgba(25, 27, 30, 0.5),
        inset 0 0 40px rgba(25, 27, 30, 0.5);
      cursor: pointer;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid $light_Black;
    }

    .ant-spin-nested-loading {
      width: 100%;
    }
    td.ant-table-column-sort {
      background: $Cool_Black;
      color: $Pure_White;
    }
    .ant-pagination {
      margin: 16px !important;
    }
  }

  .analytic_page {
    .analytic_title {
      position: sticky;
      padding: 1rem 0 1.5rem 0;
      font-size: 36px;
      font-weight: 400 !important;
      line-height: 43px;
      letter-spacing: -1px;
      color: $Mindaro_Yellow-Green;
      top: 112.8px;
      background: $Cool_Black;
      z-index: 10;
    }

    .admin-grid-2 {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
      row-gap: 2.5rem;
      column-gap: 1.5rem;
      @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      }
      .grid_section {
        .report-card {
          padding-top: 1rem;
          .recharts-wrapper {
            @media (max-width: 900px) {
              width: 100% !important;
              max-width: 600px !important;
              margin: auto;
              height: fit-content !important;
            }
          }
          .recharts-legend-wrapper {
            width: 100% !important;
          }
          .gap-5 {
            padding-right: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .dashboard_view {
    .dash_title {
      position: sticky;
      padding: 1rem 0 1.5rem 0;
      font-size: 36px;
      font-weight: 400 !important;
      line-height: 43px;
      letter-spacing: -1px;
      color: $Mindaro_Yellow-Green;
      top: 112.8px;
      background: $Cool_Black;
      z-index: 10;
    }

    .admin_grid_3 {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
      row-gap: 1.5rem;
      column-gap: 1.5rem;
      .section_grid {
        cursor: pointer;
      }
    }
  }

  .accounts_view {
    .header_view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
      padding: 1rem 0 1.5rem 0;
      background: $Cool_Black;
      position: sticky;
      top: 112.8px;
      z-index: 10;
      h1 {
        font-size: 36px;
        font-weight: 400 !important;
        line-height: 43px;
        letter-spacing: -1px;
        color: $Mindaro_Yellow-Green;
        @media (max-width: 600px) {
          display: none;
        }
      }
      .ant-input {
        max-width: 480px;
        width: 100%;
        margin-left: auto;
      }

      .ant-select {
        max-width: 180px;
        width: 100%;
        border: none;
        .ant-select-selector {
          border-radius: 4px;
        }
      }

      .ant-select-dropdown {
        box-shadow:
          0 6px 16px 0 rgba(70, 70, 70, 0.08),
          0 3px 6px -4px rgba(70, 70, 70, 0.12),
          0 9px 28px 8px rgba(70, 70, 70, 0.05) !important;
      }
    }
  }

  .users_view {
    .header_title {
      position: sticky;
      padding: 1rem 0 1.5rem 0;
      font-size: 36px;
      font-weight: 400 !important;
      line-height: 43px;
      letter-spacing: -1px;
      color: $Mindaro_Yellow-Green;
      top: 112.8px;
      background: $Cool_Black;
      z-index: 10;
    }
  }

  .ant-select {
    border: none;
  }

  .ant-pagination {
    .ant-pagination-item-active {
      background-color: $Cool_Black;
      border: 1px solid $light_Black;
    }
    .ant-pagination-item {
      background-color: $Cool_Black;
      border: 1px solid $light_Black;
      a {
        color: $Pure_White;
      }
    }
    .ant-pagination-item-active {
      a {
        color: $Mindaro_Yellow-Green !important;
      }
    }
    .ant-pagination-item-link {
      color: $Pure_White;
    }
    .ant-pagination-disabled .ant-pagination-item-link {
      color: $Pure_White;
    }
  }

  @media screen and (max-width: 600px) {
    th,
    td {
      padding: 10px !important;
      font-size: 14px !important;
    }

    .copy_btn {
      padding: 6px 10px !important;
      font-size: 12px !important;
      width: auto !important;
      height: 32px;
    }
  }
}
