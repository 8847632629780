/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $work-sans_font !important;
  font-size: 1rem;
  min-height: 100dvh;
  background: $Cool_Black;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: 0 0 0px 1000px $light_Black inset !important;
  caret-color: $Pure_White;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #a1a1a1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $work-sans_font;
  text-transform: capitalize;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  color: $Pure_White;
}

h2 {
  font-size: 18px;
  font-weight: 500;
  color: $Pure_White;

  @media (max-width: 600px) {
    display: block;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

h3,
h4 {
  font-size: 16px;
  font-weight: 400;
  color: $Pure_White;
}

h5 {
  font-size: 16px;
  font-weight: 300;
  color: $Pure_White;
}

b {
  color: $Pure_White;
  font-weight: 400;
}

label {
  color: $Pure_White;
  font-weight: 600;
}

p {
  color: $Pure_White;
  font-weight: 400;
  word-break: break-word;
}

ul {
  list-style: none;
}

.ant-divider {
  border-block-start: 1px solid #515151 !important;
  margin: 32px 0;
  @media (max-width: 600px) {
    margin: 24px 0;
  }
}
.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: $Mindaro_Yellow-Green;
}

.ant-btn-primary {
  color: $Cool_Black;
  border-radius: 3px;
  font-weight: 500;
  padding: 0px 2rem;
  font-size: 16px;
  height: 40px;
  border: 1px solid $Mindaro_Yellow-Green;
  background: $Mindaro_Yellow-Green !important;

  &:hover {
    background: $Mindaro_Yellow-Green !important;
  }

  @media (max-width: 600px) {
    font-size: 18px !important;
  }
}

.ant-btn-default {
  color: $Pure_White;
  border: 1px solid $Pure_White;
  border-radius: 3px;
  font-weight: 500;
  padding: 0 2rem;
  font-size: 16px;
  height: 40px;
  border: 1px solid $Pure_White;
  background: transparent !important;

  &:hover {
    background: $Mindaro_Yellow-Green !important;
    border: 1px solid $Mindaro_Yellow-Green !important;
  }

  @media (max-width: 600px) {
    font-size: 18px !important;
  }
}

.soft_green {
  color: $Celadon_Green !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;

  @media (max-width: 600px) {
    padding: 1rem 0 0.5rem 0 !important;
  }
}

.base-bg-color {
  background-color: $Cool_Black;
}

.br-10 {
  border-radius: $radius;
}

.p-1-5 {
  padding: 1.5rem;
}

.m-0 {
  margin: 0;
}

.px {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px 0px;
}

.bordered {
  border: 1px solid #b9b9b9;
}

.btn-add {
  color: $base-color !important;
  float: right;

  &:hover {
    color: $rg-text-color !important;
  }
}

.ms-hide {
  @media (max-width: 600px) {
    display: none;
  }
}

.ant-form-item-label {
  padding: 0 0 0.25rem !important;
}

.close_icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;

  .anticon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $gary_hover_color;
    }
  }

  .sub_header_actions {
    button {
      background: $Mindaro_Yellow-Green !important;
      color: $Cool_Black !important;
      border: none !important;

      &:hover {
        border: none !important;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }

    .card_status {
      color: $Mindaro_Yellow-Green;
      text-transform: capitalize;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}

#modal {
  display: none;
}

/* Show Modal when Targeted */
#modal:target {
  display: flex;
}
.ant-btn-link {
  &:hover {
    color: $rg-text-color !important;
  }
}

.ant-select-multiple .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap !important;
  overflow: hidden;
  max-width: 100%;
}

.ant-modal-confirm-paragraph {
  .ant-modal-confirm-title {
    color: $Pure_White;
  }

  .ant-modal-confirm-content {
    color: $Pure_White;
  }
}

.user_title,
.inv_title,
.cn_title {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tag {
  svg {
    font-size: 10px !important;
  }
}

.ant-form-item-explain-error {
  text-transform: capitalize;
}

.ant-notification-notice-message {
  text-transform: capitalize;
}

.text-capitalize {
  text-transform: capitalize;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-input:hover {
  border-color: $rg-text-color;
  border-inline-end-width: 1px;
}

.Connected {
  color: $Celadon_Green;
  font-weight: 500;
  font-size: 14px;
  // padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    // background: url("../../../assets/Union.png") no-repeat;
    background-size: contain;
    position: absolute;
    padding-left: 12px;
  }
}

.industries_form {
  padding-top: 1rem;
  @media (max-width: 600px) {
    padding-top: 2rem;
  }
}

.small_textcolor {
  color: #c6c6c6;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 1rem;
  @media (max-width: 600px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.Secondary-textcolor {
  font-size: 1.125rem;
  text-transform: capitalize;
  padding: 2rem 0 0rem 0;
  font-weight: 600;
  line-height: 1.75rem;

  @media (max-width: 600px) {
    padding: 1.5rem 0 0 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
  }

  span {
    color: $Mindaro_Yellow-Green;
  }
}

.denied {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.saveForLater {
  color: #0958d9;
  background: #e6f4ff;
  border-color: #91caff;
}

.requested {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}

.approved {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.min-h-100 {
  min-height: 100px;
}

.ant-layout {
  background-color: $Cool_Black;
}

.location {
  display: flex;
  gap: 1rem;

  label {
    margin-bottom: 5px;
    display: block;
  }
}

.ant-btn-primary {
  &:hover {
    color: $Cool_Black !important;
    background-color: $Mindaro_Yellow-Green;
  }
}

.multine-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 1rem 0;
  word-break: break-word;
}

.teammember-list {
  padding: 15px 4px;

  .ant-card-meta-title {
    text-transform: initial !important;
  }
}

.uplaod-img {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .ant-avatar {
    color: $base-color;
  }

  .ant-typography {
    font-size: 1rem;
    width: 100%;
    color: $base-color;
    margin-bottom: 0px !important;
  }

  .ant-typography-edit-content {
    max-width: 600px;
    width: 100%;
  }
}

.videoWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .video_file {
    background-color: $light_Black;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 305px;
    gap: 1rem;
  }

  .video-title-txt {
    max-width: 280px;
    font-size: 1rem;
    font-weight: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    margin: 0;
  }

  .delete_icon {
    padding: 8px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5e60714d;
    cursor: pointer;
  }
}

.flex_warp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  .video_link {
    width: 305px;
  }

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
  }
}

.deck-slideshow {
  .ant-tabs-content {
    @media (max-width: 600px) {
      min-height: 10px;
    }
  }

  // .ant-tabs-nav {
  //   margin-top: 0 !important;
  // }

  .edit-docs-view {
    padding-top: 1.5rem;

    @media (max-width: 600px) {
      padding-top: 1rem;
    }
  }

  .content {
    .upload-rounded {
      height: fit-content !important;
    }
  }

  .no-content,
  .content {
    .upload-container {
      display: flex;
      border: 1px dashed $field_stroke;
      padding: 4px 12px;
      border-radius: 4px;
      width: 305px;
      background: #0f1113;

      @media (max-width: 600px) {
        width: 100%;
      }

      .upload-box {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $Pure_White;
        cursor: pointer;
        flex-wrap: wrap;

        label {
          display: none;
        }

        @media (max-width: 600px) {
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
        }
      }

      .upload-icon {
        font-size: 24px;
      }

      .file-size-info {
        color: $filed_value_color;
        font-size: 12px;
        line-height: 18px;
      }

      input {
        max-width: 95px;
        height: 22px;
        position: relative;
        border-style: dashed;
        cursor: pointer;
        color: transparent;
        background: transparent;
        padding: 0;

        @media (max-width: 600px) {
          margin: auto;
          max-width: 100%;
        }

        &:hover {
          border-inline-end-width: 1px;
        }

        &::-webkit-file-upload-button {
          visibility: hidden;
          opacity: 0;
        }

        &::after {
          content: 'Select file';
          color: $Mindaro_Yellow-Green;
          text-align: center;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          height: 100%;
          max-width: 100%;
          width: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          transition: background-color 0.3s;
        }
      }
    }
  }
}
.content {
  .upload-rounded {
    height: fit-content !important;
  }
}

.no-content,
.content {
  .upload-container {
    display: flex;
    border: 1px dashed $field_stroke;
    padding: 4px 12px;
    border-radius: 4px;
    width: 305px;
    background: #0f1113;

    @media (max-width: 600px) {
      width: 100%;
    }

    .upload-box {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $Pure_White;
      cursor: pointer;
      flex-wrap: wrap;

      label {
        display: none;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .upload-icon {
      font-size: 24px;
    }

    .file-size-info {
      color: $filed_value_color;
      font-size: 12px;
      line-height: 18px;
    }

    input {
      max-width: 95px;
      height: 22px;
      position: relative;
      border-style: dashed;
      cursor: pointer;
      color: transparent;
      background: transparent;
      padding: 0;

      @media (max-width: 600px) {
        margin: auto;
        max-width: 100%;
      }

      &:hover {
        border-inline-end-width: 1px;
      }

      &::-webkit-file-upload-button {
        visibility: hidden;
        opacity: 0;
      }

      &::after {
        content: 'Select file';
        color: $Mindaro_Yellow-Green;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: background-color 0.3s;
      }
    }
  }
}
.comma-sign {
  position: relative;

  span {
    padding-right: 0.35rem;

    &::after {
      content: ', ';
      position: absolute;
    }

    &:last-child {
      &::after {
        content: '';
        position: absolute;
      }
    }
  }
}

.preview-docs-view {
  position: relative;

  .doc-list-content {
    border: 1px solid $light_Black;
    border-radius: 0 0 16px 16px;

    .ant-card-body {
      padding: 0 10px;
    }

    .doc-list {
      .doc-file-icon {
        width: 40px;

        svg {
          display: none;
        }
      }

      .doc-title-txt {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: rtl;
        margin: 0;
        display: none;
      }

      .anticon-delete {
        color: red;
        font-size: 1.2rem;
        visibility: hidden;
      }
    }

    .gallery_container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      /* This is the ratio: 9/16 = 0.5625 or 56.25% */
      aspect-ratio: 16 / 9;
      overflow: auto;
      // background-color: $Cool_Black; /* Optional: Background for visual clarity */

      @media (max-width: 600px) {
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        object-fit: contain;
      }
    }

    .doc_footer_control {
      padding: 18px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 600px) {
        padding: 10px 12px;
      }
    }

    .zoom-control {
      padding: 0.4rem;
      color: $Pure_White;
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .full_screen {
      display: none;

      @media (max-width: 600px) {
        display: block;
      }

      svg {
        @media (max-width: 600px) {
          width: 18px;
          height: 18px;
        }
      }
    }

    .doc-control {
      padding: 0.2rem;
      color: $Pure_White;
    }
  }
}

.file-viewer {
  min-height: 470px;
  border-radius: 16px;

  @media (max-width: 600px) {
    // margin-bottom: 3rem;
  }

  .pg-viewer-wrapper {
    overflow-y: scroll;
    max-height: 470px;
    height: 100%;
    border-radius: 16px;

    .pdf-canvas {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.edit-docs-view {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  .w-100 {
    width: 100%;
  }

  .doc_files {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: $light_Black;
    padding: 12px;
    max-width: 400px;
    border-radius: 4px;

    @media (max-width: 600px) {
      justify-content: space-between;
    }

    .file_icon {
      width: 45px;
      height: 50px;

      @media (max-width: 600px) {
        width: 40px;
        height: 45px;
      }
    }

    .load_preview {
      flex: 1;
      color: $Pure_White;
      .file_name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 180px;
        @media (max-width: 600px) {
          max-width: 140px;
          font-size: 14px;
        }
      }
    }

    .delete_icon {
      padding: 8px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #5e60714d;
      cursor: pointer;
    }

    .doc-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      position: relative;

      .doc-file-icon {
        width: 40px;
      }

      .doc-title-txt {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: rtl;
        margin: 0;
      }

      .anticon-delete {
        color: red;
        font-size: 1.2rem;
        visibility: hidden;
      }
    }
  }
}

.ant-picker-input {
  .anticon {
    color: $Pure_White !important;
  }
}

.ant-breadcrumb-link {
  text-transform: capitalize;
}

.divider {
  font-size: 1rem;
  background: $rg-text-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 1;
  padding: $size 0;
}

.text-underline {
  text-decoration: underline;
  text-decoration-color: $rg-text-color;
}

.pr-team {
  .ant-card-body {
    width: 180px;
    text-align: center;
  }
}

iframe {
  .PricingTable {
    .PriceColumn-button {
      // background-color: $btn-bg-color !important;
      // color: $base-color !important;
      // border-color: $border-color !important;
      font-weight: 500;
      max-width: fit-content;
      height: 40px;
      font-size: $size;
      margin: 0 auto;
      background-size: 300% 100%;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      text-transform: uppercase;

      &:hover {
        // background: $btn-bg-color-hover !important;
        background-position: 100% 0;

        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.ms_filter {
  display: none;

  @media (max-width: 600px) {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 1rem;
  }

  .anticon {
    width: 35px;
    height: 35px;
    border: 1px solid $Pure_White;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:active {
      border: 1px solid $Mindaro_Yellow-Green;
    }
  }
}

.enableFilters {
  @media (max-width: 600px) {
    display: block;
  }
}

.disableFilters {
  @media (max-width: 600px) {
    display: none;
  }
}

.show_count {
  position: absolute;
  right: 10px;
  font-weight: bold;
  color: #44475b;
  text-align: center;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.name-edit::placeholder {
  color: #fff;
}

.ant-modal-title {
  font-weight: 500 !important;
}

// .btn_uploader {
//   display: none !important;
// }

.btn_hide {
  display: none;
}

.list-btn-ms,
.list-btn-web {
  .ant-space-item:first-child {
    display: none;
  }
}

.pitchdeck-fullview.zoom-25 {
  transform-origin: center;
  transform: scale(0.25);
}

.pitchdeck-fullview.zoom-50 {
  transform-origin: center;
  transform: scale(0.5);
}

.pitchdeck-fullview.zoom-75 {
  transform-origin: center;
  transform: scale(0.75);
}

.pitchdeck-fullview.zoom-100 {
  transform-origin: 0 0;
  transform: scale(1);
}

.pitchdeck-fullview.zoom-125 {
  transform-origin: 0 0;
  transform: scale(1.25);
}

.pitchdeck-fullview.zoom-150 {
  transform-origin: 0 0;
  transform: scale(1.5);
}

.pitchdeck-fullview.zoom-175 {
  transform-origin: 0 0;
  transform: scale(1.75);
}

.pitchdeck-fullview.zoom-200 {
  transform-origin: 0 0;
  transform: scale(2);
}

.zoom-25 .pdf-canvas {
  transform-origin: center;
  transform: scale(0.25);
}

.zoom-50 .pdf-canvas {
  transform-origin: center;
  transform: scale(0.5);
}

.zoom-75 .pdf-canvas {
  transform-origin: center;
  transform: scale(0.75);
}

.zoom-100 .pdf-canvas {
  transform-origin: 0 0;
  transform: scale(1);
}

.zoom-125 .pdf-canvas {
  transform-origin: 0 0;
  transform: scale(1.25);
}

.zoom-150 .pdf-canvas {
  transform-origin: 0 0;
  transform: scale(1.5);
}

.zoom-175 .pdf-canvas {
  transform-origin: 0 0;
  transform: scale(1.75);
}

.zoom-200 .pdf-canvas {
  transform-origin: 0 0;
  transform: scale(2);
}

.gallery_container .zoom-25 {
  transform-origin: center;
  transform: scale(0.25);
}

.gallery_container .zoom-50 {
  transform-origin: center;
  transform: scale(0.5);
}

.gallery_container .zoom-75 {
  transform-origin: center;
  transform: scale(0.75);
}

.gallery_container .zoom-100 {
  transform-origin: 0 0;
  transform: scale(1);
}

.gallery_container .zoom-125 {
  transform-origin: 0 0;
  transform: scale(1.25);
}

.gallery_container .zoom-150 {
  transform-origin: 0 0;
  transform: scale(1.5);
}

.gallery_container .zoom-175 {
  transform-origin: 0 0;
  transform: scale(1.75);
}

.gallery_container .zoom-200 {
  transform-origin: 0 0;
  transform: scale(2);
}

/* src/Tabs.css */

.tabs {
  width: 100%;

  .tab-labels {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    gap: 2rem;
    border-bottom: 1px solid $Sand;
    position: relative;
  }

  .tab-label {
    padding: 0.75rem 0;
    cursor: pointer;
    color: $Pure_White;
    background-color: $Cool_Black;
    transition: color 0.3s ease;
    position: relative;
  }

  .tab-label.active {
    color: $Celadon_Green;
  }

  .tab-label:hover:not(.active) {
    color: $Celadon_Green;
  }

  .tab-label.active::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    width: 100%;
    background: $Celadon_Green;
    transition: width 0.3s;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .tab-content {
    color: $Pure_White;
  }
}

.profile_view {
  border-radius: 12px;
  margin-bottom: 16px;
  align-items: flex-start !important;
  position: unset !important;
  z-index: 0 !important;

  .profile_img {
    display: flex;
    gap: 1rem;
    position: relative;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .ant-avatar-circle {
      width: 7.5rem !important;
      height: 7.5rem !important;
      background: $light_Black;
      .ant-avatar-string {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        color: $gray_color;
      }
    }

    .profile-info {
      flex: 1;
      .email_view {
        display: inline-block;
        color: $filed_value_color;
        text-transform: lowercase;

        @media (max-width: 600px) {
          max-width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;
          color: $Celadon_Green;
        }
      }

      .sub_header_title {
        display: block;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.375rem;

        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
      @media screen and (max-width: 600px) {
        .JS-investments {
          text-align: left;
        }
        .frame-4 {
          align-items: flex-start;
        }
      }
    }

    .profile_view_link {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
      color: $Mindaro_Yellow-Green;
      align-self: flex-start;
      cursor: pointer;
      display: block;

      @media screen and (max-width: 600px) {
        position: absolute;
        right: 0;
      }
    }
  }
}

.fixed_title {
  width: 100%;
  padding: 1.5rem 0;

  @media (max-width: 600px) {
    top: 78px;
    text-align: center;
    margin-bottom: 24px;
    padding: 1.2rem 0;
    display: none;
  }

  @media (max-width: 1280px) {
    top: 84px;
  }

  h3 {
    font-size: 24px;
    color: $Celadon_Green;
    font-weight: 600;
    line-height: 32px;
    @media (max-width: 600px) {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.sub_header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 112.8px;
  z-index: 10;
  background-color: $Cool_Black;
  padding: 1.5rem 0;

  @media (max-width: 600px) {
    top: 84.8px;
    justify-content: space-between;
  }

  @media (max-width: 1280px) {
    top: 84.8px;
  }

  .back_icon {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    @media screen and (max-width: 600px) {
      gap: 0rem;
    }

    h2 {
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }

    h3 {
      @media (max-width: 600px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
      }
    }

    .anticon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: $gary_hover_color;
      }
    }

    .anticon-arrow-left {
      color: $Pure_White;
      font-size: 1rem;
      padding-right: 0.75rem;
    }

    .ant-avatar {
      margin-right: 0.75rem;
    }
  }

  .Secondary-textcolor {
    @media (max-width: 600px) {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      padding: 0;
    }
  }

  .sub_header_title {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 600px) {
      max-width: 160px;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
    }
  }

  .sub_header_actions {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    position: relative;

    @media (max-width: 600px) {
      // margin: auto;
      // display: block;
      // width: 100%;
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.25rem;
      .ant-btn-text {
        gap: 0.25rem;
      }
      h2 {
        @media screen and (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }

    .upload-container {
      display: flex;
      align-items: center;

      border: none;

      .upload-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        cursor: pointer;
        text-align: center;
      }

      input {
        width: 40px;
        height: 40px;
        border-radius: 26px;
        position: absolute;
        top: 40px;
        right: 15px;
        cursor: pointer;
        color: transparent;
        background: $Pure_White;
        padding: 0;
        outline: none;
        box-shadow: 0 0 2px 0 $Cool_Black;

        @media (max-width: 600px) {
          width: 35px;
          height: 35px;
          top: 30px;
        }

        &:hover {
          border-inline-end-width: 1px;
        }

        &::-webkit-file-upload-button {
          visibility: hidden;
          opacity: 0;
        }

        &::after {
          content: url('../../../../../public/assets/ph_camera-light.svg');
          background: $Pure_White;
          width: 40px;
          height: 40px;
          border-radius: 26px;
          text-align: center;
          justify-content: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: absolute;
          top: 1px;
          left: -1px;
          right: 0;
          bottom: 0px;
          transition: background-color 0.3s;

          @media (max-width: 600px) {
            width: 35px;
            height: 35px;
            top: 2px;
          }
        }
      }

      .uploading-progress {
        display: none;
      }
    }

    .ant-avatar-circle {
      width: 121px !important;
      height: 121px !important;
      border: 2px solid $Pure_White;
      position: absolute;
      top: -45px;
      right: 30px;
      border-radius: 50% !important;

      @media (max-width: 600px) {
        width: 70px !important;
        height: 70px !important;
        top: -15px;
      }
    }

    .ant-avatar-square {
      width: 121px !important;
      height: 121px !important;
      position: absolute;
      top: -45px;
      right: 30px;
      border-radius: 8px;

      @media (max-width: 600px) {
        top: -35px;
        width: 100px !important;
        height: 100px !important;
      }
    }

    .ant-btn-primary {
      margin-top: 0 !important;

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  .show_mobile {
    display: none;

    @media (max-width: 1024px) {
      display: block !important;
      margin-left: auto;
    }
  }

  @media (max-width: 1024px) {
    .hide_btn {
      display: none;
    }
  }
}

.container_profile_view {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    padding-bottom: 90px;
  }
  @media (max-width: 480px) {
  }
  .card {
    background-color: #121316;
    padding: 1rem;
    width: 25.625rem;
    min-height: 8.625rem;
    border-radius: 0.5rem;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    flex-shrink: 0;
    box-shadow:
      0px 0px 20px 0px rgba(87, 87, 87, 0.1) inset,
      0px 0px 10px 10px rgba(0, 0, 0, 0.25);
    transition: transform 0.2s;
    cursor: pointer;

    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    &:hover {
      transform: translateY(-5px);
    }

    .icon {
      font-size: 24px;
      color: #a0a0a0;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.875rem;
      color: #fff;
    }

    p {
      font-size: 0.875rem;
      color: #e4e4e4;
      line-height: 1.25rem;
      font-weight: 400;
    }
  }
}

.tab_1 {
  color: $Celadon_Green;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  margin-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid $Celadon_Green;
    width: 73px;
  }
}

//  taken from organizationList.css

.real-estate-slide-preview .sponsor-info {
  display: none !important;
  width: 0px !important;
  overflow: hidden;
}

.real-estate-slide-preview .nav-header {
  display: none !important;
}

.real-estate-slide-preview .wvp-flex-d {
  display: none !important;
}

.real-estate-slide-preview .company_poster {
  display: none !important;
}

.opp-card.active {
  border: 2px solid #b2eab6 !important;
  border-radius: 4px !important;
}

.empty-form {
  height: 214px;
}

.team_upload {
  .upload-container {
    display: flex;
    border: 1px dashed $field_stroke;
    padding: 4px 12px;
    border-radius: 4px;
    background: #0f1113;

    @media (max-width: 600px) {
      width: 100%;
    }

    .upload-box {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $Pure_White;
      cursor: pointer;
      flex-wrap: wrap;

      label {
        display: none;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .upload-icon {
      font-size: 24px;
    }

    .file-size-info {
      color: $filed_value_color;
      font-size: 12px;
      line-height: 18px;
    }

    input {
      max-width: 95px;
      height: 22px;
      position: relative;
      border-style: dashed;
      cursor: pointer;
      color: transparent;
      background: transparent;
      padding: 0;

      @media (max-width: 600px) {
        margin: auto;
        max-width: 100%;
      }

      &:hover {
        border-inline-end-width: 1px;
      }

      &::-webkit-file-upload-button {
        visibility: hidden;
        opacity: 0;
      }

      &::after {
        content: 'Select file';
        color: $Mindaro_Yellow-Green;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: background-color 0.3s;
      }
    }
  }

  .upload_wrapper {
    display: flex;
    width: 7.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    .label {
      display: inline-flex;
      align-items: flex-start;
      position: relative;
      flex: 0 0 auto;
    }

    .text-wrapper {
      position: relative;
      width: fit-content;
      margin-top: -1px;
      font-family: $work-sans_font;
      font-weight: 400;
      color: #f04438;
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 1.125rem;
      white-space: nowrap;
      font-style: normal;
    }

    .text-wrapper-2 {
      position: relative;
      width: fit-content;
      margin-top: -1px;
      font-family: $work-sans_font;
      font-weight: 500;
      color: $Pure_White;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      font-style: normal;
    }

    .img_wrapper {
      position: relative;
      flex: 0 0 auto;

      .text-wrapper-3 {
        position: relative;
        align-self: stretch;
        font-family: $work-sans_font;
        font-weight: 400;
        color: $gray_color;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 18px;
        font-style: normal;
        text-align: center;
      }

      .upload-container {
        display: flex;
        align-items: center;

        border: none;

        .upload-box {
          cursor: pointer;
          text-align: center;
        }

        input {
          width: 40px;
          height: 40px;
          border-radius: 26px;
          position: absolute;
          bottom: 40px;
          right: 0px;
          cursor: pointer;
          color: transparent;
          background: $Pure_White;
          padding: 0;
          outline: none;
          box-shadow: 0 0 2px 0 $Cool_Black;
          // @media (max-width: 600px) {
          //   width: 35px;
          //   height: 35px;
          //   top: 30px;
          // }

          &:hover {
            border-inline-end-width: 1px;
          }

          &::-webkit-file-upload-button {
            visibility: hidden;
            opacity: 0;
          }

          &::after {
            content: url('../../../../../public/assets/ph_camera-light.svg');
            background: $Pure_White;
            width: 40px;
            height: 40px;
            border-radius: 26px;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 1px;
            left: -1px;
            right: 0;
            bottom: 0px;
            transition: background-color 0.3s;

            // @media (max-width: 600px) {
            //   width: 35px;
            //   height: 35px;
            //   top: 2px;
            // }
          }
        }

        .uploading-progress {
          display: none;
        }
      }

      .ant-avatar-circle {
        width: 7.5rem !important;
        height: 7.5rem !important;
        // border: 2px solid $Pure_White;
        background-color: $light_Black;
        border-radius: 50% !important;
        // @media (max-width: 600px) {
        //   width: 70px !important;
        //   height: 70px !important;
        //   top: -15px;
        // }
      }

      .ant-avatar-square {
        width: 7.5rem !important;
        height: 7.5rem !important;
        border-radius: 8px;
        background-color: $light_Black;

        // @media (max-width: 600px) {
        //   top: -35px;
        //   width: 100px !important;
        //   height: 100px !important;
        // }
      }
    }
  }

  .ant-avatar-square {
    width: 7.5rem !important;
    height: 7.5rem !important;
    background-color: $light_Black;
    border-radius: 8px;

    // @media (max-width: 600px) {
    //   top: -35px;
    //   width: 100px !important;
    //   height: 100px !important;
    // }
  }
}

.width_div {
  width: 444px;

  @media (max-width: 600px) {
    max-width: 444px;
    width: 100% !important;
  }

  .list_group {
    .text-group {
      label {
        width: 50%;
      }
    }
  }
}

.ant-modal-confirm-btns {
  .ant-btn-default {
    @media (max-width: 600px) {
      width: unset;
    }
  }
}

.full-screen-Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensures it stays on top */

  .loader_div {
    background: rgb(0 0 0 / 84%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    padding: 12px;
    border-radius: 10px;
  }

  .loader_text {
    font-size: 16px;
    color: $Mindaro_Yellow-Green;
    word-break: break-word;
    max-width: 328px;
    text-align: center;
  }
}

.uploading-progress {
  color: $Pure_White !important;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full_view_screen {
  width: 100% !important;
  max-width: 100% !important;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @media (max-width: 600px) {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-modal-content {
    @media (max-width: 600px) {
      border-radius: 0 !important;
      padding: 16px 0px 0px 0px;
      min-height: 100dvh;
    }
  }

  .ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 18px !important;
    padding-left: 12px;
  }

  .ant-btn-default {
    &:hover {
      color: $Cool_Black !important;
    }
  }

  .controls_zoom {
    flex-wrap: wrap;

    @media (max-width: 800px) {
      display: none !important;
    }
  }

  .ant-modal-body {
    overflow: auto;
    position: absolute;
    padding: 0 12px;
  }

  .pitchdeck-fullview {
    position: relative;
    max-height: 90dvh;
    height: 100dvh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .control_overlay {
    position: fixed;
    right: 10%;
    bottom: 10%;

    .popup_zoom_contral {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 0.5rem 0.75rem;
      background-color: $light_Black;
      opacity: 0.7;
      border-radius: 4px;
      max-width: fit-content;

      .anticon {
        color: $Pure_White;
        font-size: 16px;
      }
    }
  }
}

.ql-picker-label::before {
  color: $Periwinkle;
}

.ql-snow.ql-toolbar button.ql-active {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow.ql-toolbar button:hover {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow .ql-stroke {
  stroke: $Periwinkle;
}

.ql-toolbar.ql-snow {
  font-family: $work-sans_font;
  border: 1px solid $filed_value_color !important;
}

.ql-container {
  font-family: $work-sans_font;
  border: 1px solid $filed_value_color !important;
  height: 5rem;
  background-color: $light_Black;

  ol {
    li {
      color: $Pure_White;
    }
  }

  ul {
    li {
      color: $Pure_White;
    }
  }

  a {
    color: $Periwinkle_active;
  }
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: $Mindaro_Yellow-Green;
}

.ql-snow .ql-picker-options {
  background-color: $Cool_Black;
}

.ql-snow .ql-picker {
  color: $Pure_White;
}

.ql-snow.ql-toolbar .ql-picker-item:hover {
  color: $Periwinkle_active;
}

.modal_blur {
  .modal_text {
    padding: 0 1.5rem;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    gap: 1rem;
  }
}

.modal_blur_2 {
  .modal_text {
    padding: 0 1.5rem;
  }
}

// .ant-modal-root{
//   .ant-modal-wrap {
//     width: 100%;
//     height: 100%;
//     backdrop-filter: blur(10px);
//   }
// }

.modal_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  gap: 1rem;
  padding-top: 1rem;
}

// new design profile

.account-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  overflow: hidden;

  .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div_content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 0.75rem;
      justify-content: center;
    }
  }

  .frame-2 {
    display: flex;
    gap: 16px;
    position: relative;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 0.75rem;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .div-wrapper {
    position: relative;
    width: 7.5rem;
    height: 7.5rem;
    background-color: $light_Black;
    border-radius: 100px;
    overflow: hidden;

    .ant-avatar-circle {
      width: 100%;
      height: 100%;
      object-fit: contain;

      .ant-avatar-string {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        color: $gray_color;
      }
    }
  }

  .frame-3 {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    align-self: stretch;

    @media screen and (max-width: 600px) {
      align-items: center;
    }
  }

  .text-wrapper-2 {
    width: -moz-fit-content;
    width: fit-content;
    color: #ffffff;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;

    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .frame-4 {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    position: relative;

    @media screen and (max-width: 600px) {
      justify-content: center;
      align-items: center;
    }
  }

  .JS-investments {
    width: fit-content;
    margin-top: -1px;
    font-weight: 500;
    color: $Pure_White;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 28px;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
      text-align: center;
    }
  }

  .span {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 28px;
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  // .text-wrapper-3 {
  //   color: $gray_color;
  //   font-family: $work-sans-text-LG-text-lg-regular-font-family;
  //   font-style: $work-sans-text-LG-text-lg-regular-font-style;
  //   font-weight: $work-sans-text-LG-text-lg-regular-font-weight;
  //   letter-spacing: $work-sans-text-LG-text-lg-regular-letter-spacing;
  //   line-height: $work-sans-text-LG-text-lg-regular-line-height;
  //   font-size: $work-sans-text-LG-text-lg-regular-font-size;
  // }
  .text-wrapper-4 {
    position: relative;
    width: fit-content;
    font-weight: 400;
    color: $gray_color;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 28px;
    white-space: nowrap;
    font-style: normal;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .frame-5 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0.75rem;
    position: relative;
    align-self: stretch;

    @media screen and (max-width: 600px) {
      align-items: center;
    }

    .social_label {
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;
      color: $Pure_White;
    }
  }
}

.profile_edit_content {
  gap: 0rem !important;
  .Secondary-textcolor {
    font-size: 20px;
    font-family: $work-sans_font;
    font-weight: 600;
    line-height: 30px;
    word-wrap: break-word;

    @media (max-width: 600px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding-top: 32px;
    }
  }

  .frame_1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .grid-3 {
      flex: 1 0 auto;
      gap: 0.75rem 1rem !important;
      max-width: 956px;
      width: 100%;

      @media screen and (max-width: 600px) {
        max-width: 100%;
      }
    }

    .div-2 {
      display: flex;
      width: 7.5rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      .label {
        display: inline-flex;
        align-items: flex-start;
        position: relative;
        flex: 0 0 auto;
      }

      .text-wrapper {
        position: relative;
        width: fit-content;
        margin-top: -1px;
        font-family: $work-sans_font;
        font-weight: 400;
        color: #f04438;
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 1.125rem;
        white-space: nowrap;
        font-style: normal;
      }

      .text-wrapper-2 {
        position: relative;
        width: fit-content;
        margin-top: -1px;
        font-family: $work-sans_font;
        font-weight: 500;
        color: $Pure_White;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        font-style: normal;
      }

      .div-3 {
        position: relative;
        flex: 0 0 auto;

        .upload-container {
          display: flex;
          align-items: center;

          border: none;

          .upload-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            cursor: pointer;
            text-align: center;
          }

          input {
            width: 40px;
            height: 40px;
            border-radius: 26px;
            position: absolute;
            bottom: 0px;
            right: 0px;
            cursor: pointer;
            color: transparent;
            background: $Pure_White;
            padding: 0;
            outline: none;
            box-shadow: 0 0 2px 0 $Cool_Black;

            @media (max-width: 600px) {
              width: 35px;
              height: 35px;
              top: 30px;
            }

            &:hover {
              border-inline-end-width: 1px;
            }

            &::-webkit-file-upload-button {
              visibility: hidden;
              opacity: 0;
            }

            &::after {
              content: url('../../../../../public/assets/ph_camera-light.svg');
              background: $Pure_White;
              width: 40px;
              height: 40px;
              border-radius: 26px;
              text-align: center;
              justify-content: center;
              cursor: pointer;
              display: flex;
              align-items: center;
              position: absolute;
              top: 1px;
              left: -1px;
              right: 0;
              bottom: 0px;
              transition: background-color 0.3s;

              @media (max-width: 600px) {
                width: 35px;
                height: 35px;
                top: 2px;
              }
            }
          }

          .uploading-progress {
            display: none;
          }
        }

        .ant-avatar-circle {
          width: 100px !important;
          height: 100px !important;
          border: 2px solid $Pure_White;
          border-radius: 50% !important;

          @media (max-width: 600px) {
            width: 70px !important;
            height: 70px !important;
            top: -15px;
          }
        }

        .ant-avatar-square {
          width: 100px !important;
          height: 100px !important;
          border-radius: 8px;

          @media (max-width: 600px) {
            top: -35px;
            width: 100px !important;
            height: 100px !important;
          }
        }

        // .image-plus-wrapper {
        //   position: relative;
        //   width: 100px;
        //   height: 100px;
        //   background-color: $light_Black;
        //   border-radius: 100px;
        //   overflow: hidden;
        //   }
      }
    }

    .text-wrapper-3 {
      position: relative;
      align-self: stretch;
      font-family: $work-sans_font;
      font-weight: 400;
      color: $gray_color;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 18px;
      font-style: normal;
    }
  }
}

.search_filter_wapper {
}

.firm_profile_form {
  display: flex;
  align-items: flex-start;
  gap: 1.125rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }

  .images_upload {
    .text-wrapper-3 {
      position: relative;
      align-self: stretch;
      font-family: $work-sans_font;
      font-weight: 400;
      color: $gray_color;
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 18px;
      font-style: normal;
    }

    .upload-container {
      display: flex;
      align-items: center;
      position: relative;
      border: none;

      .upload-box {
        cursor: pointer;
        position: relative;
      }

      input {
        width: 40px;
        height: 40px;
        border-radius: 26px;
        position: absolute;
        top: -35px;
        left: 90px;
        cursor: pointer;
        color: transparent;
        background: $Pure_White;
        padding: 0;
        outline: none;
        box-shadow: 0 0 2px 0 $Cool_Black;
        // @media (max-width: 600px) {
        //   width: 35px;
        //   height: 35px;
        //   top: 30px;
        // }

        &:hover {
          border-inline-end-width: 1px;
        }

        &::-webkit-file-upload-button {
          visibility: hidden;
          opacity: 0;
        }

        &::after {
          content: url('../../../../../public/assets/ph_camera-light.svg');
          background: $Pure_White;
          width: 40px;
          height: 40px;
          border-radius: 26px;
          text-align: center;
          justify-content: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: absolute;
          top: 1px;
          left: -1px;
          right: 0;
          bottom: 0px;
          transition: background-color 0.3s;

          // @media (max-width: 600px) {
          //   width: 35px;
          //   height: 35px;
          //   top: 2px;
          // }
        }
      }

      .uploading-progress {
        display: none;
      }
    }
  }

  .upload_wrapper {
    display: flex;
    width: 7.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    .label {
      display: inline-flex;
      align-items: flex-start;
      position: relative;
      flex: 0 0 auto;
    }

    .text-wrapper {
      position: relative;
      width: fit-content;
      margin-top: -1px;
      font-family: $work-sans_font;
      font-weight: 400;
      color: #f04438;
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 1.125rem;
      white-space: nowrap;
      font-style: normal;
    }

    .text-wrapper-2 {
      position: relative;
      width: fit-content;
      margin-top: -1px;
      font-family: $work-sans_font;
      font-weight: 500;
      color: $Pure_White;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      font-style: normal;
    }

    .img_wrapper {
      position: relative;
      flex: 0 0 auto;

      .text-wrapper-3 {
        position: relative;
        align-self: stretch;
        font-family: $work-sans_font;
        font-weight: 400;
        color: $gray_color;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 18px;
        font-style: normal;
        text-align: center;
      }

      .upload-container {
        display: flex;
        align-items: center;

        border: none;

        .upload-box {
          cursor: pointer;
          text-align: center;
        }

        input {
          width: 40px;
          height: 40px;
          border-radius: 26px;
          position: absolute;
          bottom: 40px;
          right: 0px;
          cursor: pointer;
          color: transparent;
          background: $Pure_White;
          padding: 0;
          outline: none;
          box-shadow: 0 0 2px 0 $Cool_Black;
          // @media (max-width: 600px) {
          //   width: 35px;
          //   height: 35px;
          //   top: 30px;
          // }

          &:hover {
            border-inline-end-width: 1px;
          }

          &::-webkit-file-upload-button {
            visibility: hidden;
            opacity: 0;
          }

          &::after {
            content: url('../../../../../public/assets/ph_camera-light.svg');
            background: $Pure_White;
            width: 40px;
            height: 40px;
            border-radius: 26px;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 1px;
            left: -1px;
            right: 0;
            bottom: 0px;
            transition: background-color 0.3s;

            // @media (max-width: 600px) {
            //   width: 35px;
            //   height: 35px;
            //   top: 2px;
            // }
          }
        }

        .uploading-progress {
          display: none;
        }
      }

      .ant-avatar-circle {
        width: 7.5rem !important;
        height: 7.5rem !important;
        // border: 2px solid $Pure_White;
        background-color: $light_Black;
        border-radius: 50% !important;
        // @media (max-width: 600px) {
        //   width: 70px !important;
        //   height: 70px !important;
        //   top: -15px;
        // }
      }

      .ant-avatar-square {
        width: 7.5rem !important;
        height: 7.5rem !important;
        border-radius: 8px;
        background-color: $light_Black;

        // @media (max-width: 600px) {
        //   top: -35px;
        //   width: 100px !important;
        //   height: 100px !important;
        // }
      }
    }
  }

  .ant-avatar-square {
    width: 7.5rem !important;
    height: 7.5rem !important;
    background-color: $light_Black;
    border-radius: 8px;

    // @media (max-width: 600px) {
    //   top: -35px;
    //   width: 100px !important;
    //   height: 100px !important;
    // }
  }
}
.label {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}
.soft_green_title {
  font-size: 1.25rem;
  line-height: 30px;
  color: $Celadon_Green;
  font-weight: 600;
  padding-bottom: 16px;

  @media (max-width: 600px) {
    font-size: 1.125rem;
    line-height: 28px;
  }
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgb(48, 53, 59, 0.25);
}

.ant-switch {
  background: $light_Black;

  .ant-switch-checked {
    background: $Mindaro_Yellow-Green;
  }

  .ant-switch-inner {
    .ant-switch-inner-checked {
      color: $Cool_Black;
    }
  }
}

.message-box {
  background-color: $Cool_Black;
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;
  box-shadow: 0 -2px 7px 0 $light_Black;

  display: none;

  @media screen and (max-width: 600px) {
    display: block !important;
    position: fixed;
    bottom: 65px;
    left: 0;
    right: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    max-width: 100% !important;
  }

  p {
    font-size: 14px;
    color: #a1a1a1;
    line-height: 20px;
    padding-bottom: 16px;
  }

  button {
    background-color: $Mindaro_Yellow-Green;
    color: $Cool_Black !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #b8e64b;
  }
}

.pointer-events {
  @media screen and (min-width: 600px) {
    pointer-events: none;
  }
}

.ant-input-textarea-show-count .ant-input-data-count {
  color: $gray_color;
  font-size: 12px;
}

.textarea_height {
  .ql-container {
    height: 9.4rem;
  }
}

textarea.ant-input {
  border-radius: 4px !important;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
  color: $Mindaro_Yellow-Green;
}

.ant-input-affix-wrapper .ant-input-clear-icon:hover {
  color: $Mindaro_Yellow-Green;
}

.account-profile-modal {
  .ant-modal-content {
    border: 0 !important;
    background-color: #0f1217 !important;
  }
}
